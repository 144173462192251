import Microscope from '../../../images/microscope.png';
import Cabinet from '../../../images/cabinet.png';
import Incubator from '../../../images/incubator.png';
import Glucometer from '../../../images/glucometer.png';
import Centrifuge from '../../../images/centry-fuge.png';
import MultiChannel from '../../../images/multichannel.png';
import American from '../../../images/american.png';
import Eclipse from '../../../images/eclipse.png';
import MIKRO from '../../../images/mikro.png';


const products = [
    {
      id: 1,
      name: 'Laboratory Incubator',
      category: ['Agriculture', 'Water Testing','Eliza Kits','Glassware','BD',],
      price: 'KSh55.00',
      rating: 5,
      cover: Microscope,
      createdAt: new Date('2023-01-01')
    },
    {
      id: 2,
      name: 'Glucometer',
      category: ['BD', 'Filter Paper','Finecare','Haematology','Agriculture',],
      price: 'KSh200.00',
      rating: 5,
      cover: Cabinet,
      createdAt: new Date('2023-02-01')
    },
    {
      id: 3,
      name: 'Ultra Low Freezer',
      category: ['Blood Sugar', 'Histology','Hb-Efk','HPLC','Agriculture',],
      price: 'KSh88.00',
      rating: 5,
      cover: Incubator,
      createdAt: new Date('2023-02-01')
    },
    {
      id: 4,
      name: 'Esco Biosafety Cabinet',
      category: ['Chemicals', 'University Items','Lab Accessories','Lab Equipment'],
      price: 'KSh23.00',
      rating: 5,
      cover: Glucometer,
      createdAt: new Date('2023-02-01')
    },
    {
      id: 5,
      name: 'Digital Centrifuge',
      category: ['Clinical Chemistry', 'MISC','Matrix','Micropipette'],
      price: 'KSh230.00',
      rating: 5,
      cover: Centrifuge,
      createdAt: new Date('2023-02-01')
    },
    {
      id: 6,
      name: 'CappAero Multichannel',
      category: ['Culture Media', 'Diagnostic Reagents','PCR','Randox'],
      price: 'KSh40.00',
      rating: 5,
      cover: MultiChannel,
      createdAt: new Date('2023-02-01')
    },
    {
      id: 7,
      name: 'All American Model #50X',
      category: ['Diagnostic Reagents', 'School Items','Spares','Surgicals'],
      price: 'KSh350.00',
      rating: 5,
      cover: American,
      createdAt: new Date('2023-02-01')
    },
    {
      id: 8,
      name: 'Eclipse E100',
      category: ['Diesse', 'Technilamp','Teaching Kits','Urinalysis'],
      price: 'KSh620.00',
      rating: 5,
      cover: Eclipse,
      createdAt: new Date('2023-02-01')
    },
    {
      id: 9,
      name: 'MIKRO 220',
      category: ['Diesse', 'Reagents','MISC','Spares'],
      price: 'KSh20.00',
      rating: 5,
      cover: MIKRO,
      createdAt: new Date('2023-02-01')
    },
];

export {products}