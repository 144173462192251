import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import fileFill from '@iconify/icons-eva/file-fill';
import { PATH_AUTH, PATH_DOCS, PATH_PAGE, PATH_DASHBOARD } from '../../routes/paths';

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfigChemoquip = [
  {
    title: 'Home',
    icon: 'material-symbols--home',
    path: '/'
  },
  {
    title: 'Products',
    icon: 'flowbite--shopping-bag-solid',
    path: '/products'
  },
  {
    title: 'About Us',
    icon: 'clarity--group-line',
    path: '/about-us'
  },
  {
    title: 'Our Services',
    icon: 'medical-icon--i-social-services',
    path: '/our-services'
  },
  {
    title: 'Contact Us',
    icon: 'hugeicons--contact-02',
    path: '/contact-us'
  },
  {
    title: 'Information',
    icon: 'pajamas--information',
    children: [
      {
        subheader: 'Contact',
        items: [
          { title: '+254 733 641982', href: 'tel:+254 733 641982', icon: 'ph--phone-fill' },
          { title: 'cql@chemoquip.com', href: 'mailto:cql@chemoquip.com', icon: 'mdi--email' },
          { title: 'Store Location', path: '/contact-us', icon: 'f7--location-fill' },
        ]
      },
    ]
  },
];

export default menuConfigChemoquip;
