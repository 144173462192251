import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Grid, Link, Divider, Container, Typography, IconButton, Stack, Box, TextField } from '@material-ui/core';
// routes
import { PATH_PAGE } from '../../routes/paths';
//
import Logo from '../../components/Logo';
import { LoadingButton } from '@material-ui/lab';
import LogoNew from '../../images/CQL_Logo.png'
import LogoPartner1 from '../../images/american express.png'
import LogoPartner2 from '../../images/solo-pink.png'
import LogoPartner3 from '../../images/solo-green.png'
import LogoPartner4 from '../../images/eway.png'
// ----------------------------------------------------------------------

const ThumbImgStyle = styled('img')(({ theme }) => ({
  width: 70,
  height: 40,
  objectFit: 'fill',
  opacity:'0.7'
  // margin: theme.spacing(0, 2),
  // borderRadius: theme.shape.borderRadiusSm
}));


const SOCIALS = [
  { name: 'Twitter', icon: 'mdi--twitter' },
  { name: 'FaceBook', icon: 'ic--sharp-facebook' },
  { name: 'Google', icon: 'mdi--google-plus' },
  { name: 'Tumblr', icon: 'fa-brands--tumblr' },
];

const itemsImg = [
  {
    imageSrc: LogoPartner1,
    altText: 'Description of image 1',
  },
  {
    imageSrc: LogoPartner2,
    altText: 'Description of image 2',
  },
  {
    imageSrc: LogoPartner3,
    altText: 'Description of image 3',
  },
  {
    imageSrc: LogoPartner4,
    altText: 'Description of image 4',
  },
];


const LINKS = [
  {
    headline: 'Contact us',
  },
  {
    headline: 'Recent Posts',
    children: [
      { name: 'Products', href: '/products' },
      { name: 'About Us', href: '/about-us' },
    ]
  },
  // {
  //   headline: 'Our Products',
  //   children: [
  //     { name: 'Product', href: '/' },
  //     { name: 'News', href: '/' }
  //   ]
  // },
  {
    headline: 'Information',
    children: [
      { name: 'Our Service', href: '/our-services' },
      { name: 'Contact Us', href: '/contact-us' }
    ]
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#232323'
}));

const items = [
  'Store Location',
  'Best Products',
  'New Products'
];

// ----------------------------------------------------------------------

export default function ChemoquipFooter() {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  return (
    <RootStyle>
      <Divider sx={{borderColor:'#232323'}} />
      <Box maxWidth="lg">
        <Grid
          container
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 2,pb:3, pl: { xs: 3, sm: 3, md: 6 }, pr: { xs: 3, sm: 3, md: 6 }, backgroundColor: '#111111' }}
        >
          <Grid item xs={12} md={4} container justifyContent={{xs:"center", sm:"center", md: "flex-start"}} alignItems={{xs:"center", sm:"center", md: "flex-start"}} sx={{mb:{xs:4,sm:4,md:0}}}>
            <ScrollLink to="move_top" spy smooth>
            <img src={LogoNew} style={{cursor:'pointer',width:'250px',height:'94px'}} alt='Logo' />
              {/* <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} /> */}
            </ScrollLink>
          </Grid>

          <Grid item xs={12} md={4} container direction="column" justifyContent="center" alignItems="center" sx={{mb:{xs:4,sm:4,md:0}}}>
            <TextField
              fullWidth
              sx={{
                mb: 2,
                '& .MuiInputBase-root': {
                  backgroundColor: '#263036',
                  color:'common.white'
                },
              }}
              label="Email Address"
            />
            <LoadingButton 
              size="large" 
              sx={{ 
                width: 'auto', 
                px: 3, 
                boxShadow: 'none', 
                alignSelf: 'center' 
              }} 
              type="submit" 
              variant="contained"
            >
              Subscribe
            </LoadingButton>
          </Grid>
            
          <Grid item xs={12} md={4} container justifyContent={{xs:"center", sm:"center", md: "flex-end"}} alignItems={{xs:"center", sm:"center", md: "flex-end"}}>
            {SOCIALS.map((social) => (
              <>
              <IconButton key={social.name} color="primary" sx={{boxShadow:'none'}}>
                <Stack className={social.icon} sx={{color:'#fff',fontSize:'2rem','&:hover': {
                  color:'primary.main'
                }}} />
              </IconButton>
              </>
            ))}
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: '#232323',mt:2,mb:2 }} />
        <Grid
          container
          justifyContent="space-between"
          sx={{ textAlign: { xs: 'left', md: 'left' }, backgroundColor:'#111111',pt: 0, pb: 3, pl: { xs: 3, sm: 6, md: 10 }, }}
          spacing={4}
        >
          {LINKS.map((list) => {
            const { headline, children } = list;
            return (
              <Grid item xs={12} sm={6} md={3} key={headline} >
                <Stack spacing={2}>
                  <Typography component="p" variant="overline" color={'common.white'}>
                    {headline}
                  </Typography>
                  {headline !== 'Contact us' && children.map((link) => (
                    <Link
                      to={link.href}
                      key={link.name}
                      color="common.white"
                      variant="body2"
                      underline='none'
                      component={RouterLink}
                      sx={{ display: 'block','&:hover': {opacity: '0.7'}, }}
                    >
                      {link.name}
                    </Link>
                  ))}
                  {headline === 'Contact us' && (
                    <>
                    <Link
                      color="common.white"
                      variant="body2"
                      underline='none'
                      sx={{ display: 'block', marginTop: '8px' }}
                    >
                      47 Kipande Road, Near<br/>
                      Globe Roundabout, Nairobi,<br/>
                      Kenya
                    </Link>
                    <Stack direction={'row'}>
                      <Stack className='iconamoon--phone-fill' sx={{fontSize:'1.3rem',color:'common.white',}} />
                      <Link
                        href="tel:+254 733 641982"
                        color="common.white"
                        variant="body2"
                        underline='none'
                        sx={{ml:1,mt:-0.1}}
                      >
                        +254 733 641982
                      </Link>
                    </Stack>
                    <Stack direction={'row'}>
                      <Stack className='mdi--email' sx={{fontSize:'1.3rem',color:'common.white',}} />
                      <Link
                        href="mailto:cql@chemoquip.com"
                        color="common.white"
                        variant="body2"
                        underline='none'
                        sx={{ml:1,mt:-0.1}}
                      >
                        cql@chemoquip.com
                      </Link>
                    </Stack>
                    </>
                  )}
                </Stack>
              </Grid>
            );
          })}
        </Grid>
        <Divider sx={{borderColor:'#232323'}} />
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'center' }}
          sx={{ textAlign: { xs: 'center', md: 'center' }, gap: {xs:0,sm:2,md:2},backgroundColor:'#111111' }}
        >
          {items.map((item, index) => (
            <>
              <Link
                key={index}
                component="p"
                variant="body2"
                underline='none'
                onClick={() => navigate('/products')}
                sx={{
                  mt: 3,
                  pb: 3,
                  pl:{xs:1,sm:0,md:0},
                  pr:{xs:1,sm:0,md:0},
                  fontSize: 13,
                  color: 'common.white',
                  cursor: 'pointer',
                  textAlign: { xs: 'center', md: 'center' },
                  '&:hover':{
                    opacity:'0.7'
                  }
                }}
              >
                {item}
              </Link>
              <Typography
                sx={{
                  mt: 3,
                  pb: 3,
                  fontSize: 13,
                  color: 'common.white',
                  textAlign: { xs: 'center', md: 'center' },
                }}
              >
                {index < items.length - 1 && ' | '}
              </Typography>
            </>
          ))}
        </Grid>

        <Divider sx={{borderColor:'#232323'}} />
        <Grid
          container
          justifyContent={ {xs: 'center', md: 'space-between'} }
          sx={{
            textAlign: 'center',
            backgroundColor: 'black',
            pl: { xs: 3, sm: 3, md: 6 },
            pr: { xs: 3, sm: 3, md: 6 },
            pb:{xs:3,sm:3,md:0}
          }}
        >
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' }, alignItems: 'center' }}>
            <Typography
              component="p"
              variant="body2"
              sx={{
                mt: 3,
                pb: 2,
                fontSize: 13,
                textAlign: { xs: 'center', md: 'left' },
                color: 'text.secondary',
              }}
            >
              Designed by skyniche Production © Chemoquip Limited {currentYear}
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={6} md={4} justifyContent={{ xs: 'center', md: 'flex-end' }} sx={{ mt: 1.7,gap:1,pb:{xs:0,sm:0,md:0.5} }}>
            {itemsImg.map((item, index) => (
              <Grid item key={index}>
                <ThumbImgStyle
                  src={item.imageSrc}
                  alt={item.altText}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

      </Box>
    </RootStyle>
  );
}
