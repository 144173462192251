import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Button, AppBar, Toolbar, Container, Stack, Link, Divider } from '@material-ui/core';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
import { MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfigChemoquip';
import LogoNew from '../../images/CQL_Logo.png'
import Searchbar from '../dashboard/Searchbar';
import SearchMainNavbar from './SearchMainNavbar';
import { products } from 'src/pages/chemoquip-website/chemoquip-products/data';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 88;
const APP_BAR_MOBILE1 = 58;
const APP_BAR_DESKTOP = 98;
const APP_BAR_DESKTOP_SMALL = 52;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  },
}));
const ToolbarStyle1 = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE1,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  },
}));

const ToolbarStyle2 = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_DESKTOP_SMALL,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP_SMALL
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const isOffset1 = useOffSetTop(600);
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const navigate = useNavigate();
  return (
    <>
    <AppBar sx={{ boxShadow: 0,height:'45px',backgroundColor:'#fff',display:{xs:'none',sm:'none',md:'block'} }}>
      <ToolbarStyle2
        disableGutters
        
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop:'-15px',
            justifyContent: 'flex-end'
          }}
        >

        <Box sx={{ flexGrow: 1 }} />
          <Stack direction={'column'} sx={{display:{xs:'none',sm:'none',md:'flex',}}} spacing={2}>
            <Stack direction={'row'} spacing={2} sx={{pr:4}}>
              <Stack direction={'row'}>
                <Stack className='ph--phone-fill' sx={{fontSize:'1.3rem',color:'primary.main',}} />
                <Link
                  href="tel:+254 733 641982"
                  color="text.primary"
                  variant="body2"
                  underline='none'
                  sx={{ml:1,mt:-0.1,fontWeight:500,cursor:'pointer',fontSize:'14px',}}
                >
                  +254 733 641982
                </Link>
              </Stack>
              <Stack direction={'row'}>
                <Stack className='mdi--email' sx={{fontSize:'1.3rem',color:'primary.main',}} />
                <Link
                  href="mailto:cql@chemoquip.com"
                  color="text.primary"
                  variant="body2"
                  underline='none'
                  sx={{ml:1,mt:-0.2,fontWeight:500,cursor:'pointer',fontSize:'15px',}}
                >
                  cql@chemoquip.com
                </Link>
              </Stack>
              <Stack direction={'row'}>
                <Stack className='f7--location-fill' sx={{fontSize:'1.3rem',color:'primary.main',}} />
                <Link
                  href="/contact-us"
                  color="text.primary"
                  variant="body2"
                  underline='none'
                  sx={{ml:1,mt:-0.2,fontWeight:500,cursor:'pointer',fontSize:'15px',}}
                >
                  Store Location
                </Link>
              </Stack>
            </Stack>
            {/* <Stack direction={'row'} sx={{mt:{xs:1,sm:1,md:0},display:'flex',justifyContent:'flex-end'}}>
              <Stack className='f7--location-fill' sx={{fontSize:'1.3rem',color:'primary.main',}} />
              <Link
                color="text.primary"
                variant="body2"
                underline='none'
                sx={{ml:1,mt:-0.2,fontWeight:500,cursor:'pointer',fontSize:'15px',}}
              >
                Store Location
              </Link>
            </Stack> */}
          </Stack>
        </Container>
      </ToolbarStyle2>

    </AppBar>
    <AppBar color={isHome ? 'default' : 'default'} sx={{ height:{xs:'fit-content',sm:'fit-content',md:'70px'},backgroundColor:'#fff',boxShadow: 0,marginTop:{xs:'0px',sm:'0px',md:'35px' }}}>
    {/* <AppBar color={isHome ? 'transparent' : 'default'} sx={{ boxShadow: 0 }}> */}
      <ToolbarStyle
        disableGutters
        sx={{
          
          ...(isOffset && {
            bgcolor: 'background.default',
            // height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <RouterLink to="/">
            {/* <Logo /> */}
            <img src={LogoNew} className='logo-responsive' style={{zIndex:999}} alt='Logo' />
          </RouterLink>
          {/* <Label color="info" sx={{ ml: 1 }}>
            v2.1.0
          </Label> */}
          <Box sx={{ flexGrow: 1 }} />
          
          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>

          {/* <Button variant="contained" target="_blank" href="https://material-ui.com/store/items/minimal-dashboard/">
            Purchase Now
          </Button> */}

          {/* <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden> */}
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle /> }
    </AppBar>
    <AppBar color={isHome ? 'default' : 'default'} sx={{ height:'40px',boxShadow: 0 ,mt:{xs:10.7,sm:10.7,md:13,},backgroundColor:'#fff',borderTop:'1px solid #e6e6e6'}}>
      <ToolbarStyle2
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop:{xs:'-14px',sm:'-22px',md:'-22px'},
          }}
        >
          {/* <Button sx={{fontWeight:400}} startIcon={<Stack className='material-symbols--menu' sx={{fontSize:'1.3rem',color:'primary.main',fontWeight:400}} />}>
           Menu
          </Button> */}
          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction={'row'} spacing={1}>
          <Link
            color="primary.main"
            variant="body2"
            underline='none'
            onClick={() => navigate('/products')}
            sx={{fontWeight:500,cursor:'pointer',fontSize:'15px','&:hover': { opacity: 0.72 }}}
          >
            Get A Quote
          </Link>
          <Link
            variant="body2"
            underline='none'
            sx={{fontWeight:500,cursor:'pointer',fontSize:'15px',color:'#ccc'}}
          >
           |
          </Link>
          <Link
            color="primary.main"
            variant="body2"
            underline='none'
            sx={{fontWeight:500,cursor:'pointer',fontSize:'15px','&:hover': { opacity: 0.72 }}}
          >
            {products.length} Items
          </Link>
          <SearchMainNavbar />
          </Stack>
        </Container>
      </ToolbarStyle2>

      {/* {isOffset && <ToolbarShadowStyle />} */}
    </AppBar>
    </>
  );
}
